import React from 'react'
import ResponsiveDrawer from './ResponsiveDrawer'

const Layout = ({ children }) => {
    return (
        <section className="default-bg-color">
            <ResponsiveDrawer children={children}/>
        </section>
    )
}

export default Layout