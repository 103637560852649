import { BrowserRouter, Routes, Route, Navigate, useLocation } from 'react-router-dom';
import Home from './components/Home';
import './styles/globals.scss'
import Signup from './pages/Signup';
import Login from './pages/Login';
import About from './pages/About';
import News from './pages/News';
import Layout from './components/Layout';
import Tokenomics from './pages/Tokenomics';
import Marketplace from './pages/Marketplace';
import Profile from './pages/Profile';
import { ROUTES, TOKEN_NAME } from './config';
import Contact from './pages/Contact';


function MainLayoutRoutes() {
  const location = useLocation();

  const RequireAuth = ({ children }) => {
    const token = sessionStorage.getItem(TOKEN_NAME);
    return token !== null ? children : <Navigate to={ROUTES.LOGIN} replace state={{ path: location.pathname }} />;
  }

  return (
    <>
      <Routes>
        <Route index element={
          <Layout>
            <Home />
          </Layout>} />
        <Route path={ROUTES.HOME} element={
          <Layout>
            <Home />
          </Layout>} />
          <Route path={ROUTES.ABOUT} element={
          <Layout>
            <About />
          </Layout>} />     
          <Route path={ROUTES.NEWS} element={
          <Layout>
            <News />
          </Layout>} />
        <Route path={ROUTES.CONTACT} element={
          <Layout>
            <Contact />
          </Layout>} />
        <Route path={ROUTES.TOKENOMICS} element={
          <RequireAuth>
            <Layout>
              <Tokenomics />
            </Layout>
          </RequireAuth>} />
        <Route path={ROUTES.MARKETPLACE} element={
          <RequireAuth>
            <Layout>
              <Marketplace />
            </Layout>
          </RequireAuth>} />
        <Route path={ROUTES.PROFILE} element={
          <RequireAuth>
            <Layout>
              <Profile />
            </Layout>
          </RequireAuth>} />
      </Routes>
    </>
  )
}


const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path={ROUTES.LOGIN} element={<Login />} />
        <Route path={ROUTES.SIGNUP} element={<Signup />} />
        <Route path='*' element={<MainLayoutRoutes />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
