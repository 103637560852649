import React, { useState } from 'react';
import Text from '../components/elements/Text';
import { NavLink, useNavigate } from 'react-router-dom';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { auth, db } from '../firebase';
import { collection, addDoc } from 'firebase/firestore';
import { DB_COLLECTIONS } from '../config';

const Signup = () => {
    const navigate = useNavigate();

    // const [firstName, setFirstName] = useState('')
    // const [lastName, setLastName] = useState('')
    const [applicationType, setApplicationType] = useState('collector')
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('');

    const handleChange = (event) => {
        setApplicationType(event.target.value)
    }

    const onSubmit = async (e) => {
        e.preventDefault()

        createUserWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {
                // Signed in 
                var userUid = auth.currentUser.uid;
                addDoc(collection(db, DB_COLLECTIONS.CUSTOMER), {
                    AuthId: userUid,
                    name: name,
                    email: email,
                    applicationType: applicationType,
                }).then(data => console.log(data));

                navigate("/login")
                // ...
            })
            .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                console.log(errorCode, errorMessage);
                // ..
            });
    }

    return (
        <div className="App default-bg-color">
            <main >
                <section>
                    <div className="flex h-screen items-center justify-center px-4 sm:px-6 lg:px-8">
                        <div className="w-full max-w-md space-y-8">
                            <div>
                                <Text className="text-4xl text-white text-center font-bold mb-2">
                                    MERICULUM
                                </Text>

                                <h2 className="text-white text-center text-base  tracking-tight">
                                    Are you new? Sign up today
                                </h2>
                            </div>


                            <form onSubmit={onSubmit} className="mt-8 space-y-6" >
                                <div className=" space-y-6 rounded-md shadow-sm">
                                    <div>
                                        <label htmlFor="name" className="sr-only">
                                            Company / Name
                                        </label>
                                        <input
                                            type="text"
                                            label="Company or Name"
                                            value={name}
                                            onChange={(e) => setName(e.target.value)}
                                            required
                                            className="relative block w-full appearance-none rounded-none rounded-t-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                            placeholder="Company / Name"
                                        />
                                    </div>

                                    <div>
                                        <label htmlFor="email-address" className="sr-only">
                                            Email address
                                        </label>
                                        <input
                                            type="email"
                                            label="Email address"
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                            required
                                            className="relative block w-full appearance-none rounded-none rounded-t-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                            placeholder="Email address"
                                        />
                                    </div>

                                    <div>
                                        <label htmlFor="password" className="sr-only">
                                            Password
                                        </label>
                                        <input
                                            type="password"
                                            label="Create password"
                                            value={password}
                                            onChange={(e) => setPassword(e.target.value)}
                                            required
                                            className="relative block w-full appearance-none rounded-none rounded-b-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                            placeholder="Password"
                                        />
                                    </div>
                                </div>

                                <div>
                                    <p className='text-white font-bold'>Applying as:</p>
                                    <input onChange={handleChange} type="radio" id="collector" name="apply_type" value="collector" checked={applicationType === 'collector'} />
                                    <label className="text-white mt-px inline-block pl-[0.15rem] hover:cursor-pointer" htmlFor="collector">Plastic Collector</label><br />
                                    <input onChange={handleChange} type="radio" id="factory" name="apply_type" value="factory" checked={applicationType === 'factory'} />
                                    <label className="text-white mt-px inline-block pl-[0.15rem] hover:cursor-pointer" htmlFor="factory">Recycle/Reuse Factory</label><br />
                                    <input onChange={handleChange} type="radio" id="sponsor" name="apply_type" value="sponsor" checked={applicationType === 'sponsor'} />
                                    <label className="text-white mt-px inline-block pl-[0.15rem] hover:cursor-pointer" htmlFor="sponsor">Plastic Sponsor</label><br /><br />
                                </div>

                                <div>
                                    <button
                                        type="submit"
                                        className="group relative flex w-full justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                    >
                                        Sign up
                                    </button>
                                </div>

                            </form>


                            <p className="text-sm text-white text-center">
                                Already have an account?{' '}
                                <NavLink to="/login" className="underline text-tertiary">
                                    Sign in
                                </NavLink>
                            </p>

                        </div>
                    </div>
                </section>
            </main>
        </div>
    )
}

export default Signup