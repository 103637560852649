import React, { useState, useEffect } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import Toolbar from '@mui/material/Toolbar';
import HomeIcon from '@mui/icons-material/Home';
import MenuIcon from '@mui/icons-material/Menu';
import RouteIcon from '@mui/icons-material/Route';
import AnnouncementIcon from '@mui/icons-material/Announcement';
import TokenIcon from '@mui/icons-material/Token';
import StorefrontIcon from '@mui/icons-material/Storefront';
import PersonIcon from '@mui/icons-material/Person';

import logo from '../assets/images/logo4.png'

import { auth } from '../firebase';
import { signOut } from 'firebase/auth';
import { useNavigate, Link as RouterLink } from 'react-router-dom';
import { ROUTES, TOKEN_NAME } from '../config';
import Footer from './Footer';
import { Divider } from '@mui/material';

const drawerWidth = 240;

function ResponsiveDrawer(props) {
  const { window, children } = props;
  const [mobileOpen, setMobileOpen] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const navigate = useNavigate();


  useEffect(() => {
    const token = sessionStorage.getItem(TOKEN_NAME);
    setIsAuthenticated(token !== null);
  }, [isAuthenticated])

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleLogout = () => {
    signOut(auth).then(() => {
      sessionStorage.removeItem(TOKEN_NAME);
      navigate(0);
      // navigate(ROUTES.HOME, { });
      // window.location.reload();
    }).catch((error) => {
      console.log(error);
    });
  }

  const handleLogin = () => {
    navigate(ROUTES.LOGIN);
  }

  const Link = React.forwardRef(function Link(itemProps, ref) {
    return <RouterLink ref={ref} {...itemProps} role={undefined} />;
  });

  const ListItemLink = (props) => {
    const { icon, primary, to } = props;

    return (
      <li>
        <ListItem component={Link} to={to}>
          {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
          <ListItemText primary={primary} sx={{ color: 'white' }} />
        </ListItem>
      </li>
    );
  }

  const drawer = (
    <div>
      {/* Toolbar works like a marginTop */}
      <Toolbar />
      <Toolbar />
      <List>
        <ListItemLink
          to={ROUTES.HOME}
          primary="HOME"
          icon={<HomeIcon sx={{ color: 'white' }} />} />
        <ListItemLink
          to={ROUTES.ABOUT}
          primary="ROADMAP"
          icon={<RouteIcon sx={{ color: 'white' }} />} />
        <ListItemLink
          to={ROUTES.NEWS}
          primary="NEWS"
          icon={<AnnouncementIcon sx={{ color: 'white' }} />} />
        {
          isAuthenticated ?
            <>
              <Divider />
              <ListItemLink
                to={ROUTES.TOKENOMICS}
                primary="TOKENOMICS"
                icon={<TokenIcon sx={{ color: 'white' }} />} />
              <ListItemLink
                to={ROUTES.MARKETPLACE}
                primary="MARKETPLACE"
                icon={<StorefrontIcon sx={{ color: 'white' }} />} />
              <ListItemLink
                to={ROUTES.PROFILE}
                primary="PROFILE"
                icon={<PersonIcon sx={{ color: 'white' }} />} />
            </>
            : null
        }
      </List>
    </div>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <Box className="default-bg-color" sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="fixed" sx={{ backgroundColor: 'black', zIndex: (theme) => theme.zIndex.drawer + 1 }}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            as="a"
            href={ROUTES.HOME}
            variant="small"
            component="div" sx={{ flexGrow: 1 }}
          >
            <img className='' width="150" alt='mericulum logo' src={logo}></img>
          </Typography>
          {
            isAuthenticated
              ?
              <Button variant="gradient" size="sm" className="hidden lg:inline-block" onClick={handleLogout}>
                <span>Logout</span>
              </Button>
              :
              <Button variant="gradient" size="sm" className="hidden lg:inline-block" onClick={handleLogin}>
                <span>Login</span>
              </Button>
          }
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 }, marginTop: '40px', backgroundColor: '#367e94' }}
        aria-label="mailbox folders"
      >
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth, backgroundColor: '#367e94' },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', sm: 'block' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth, backgroundColor: '#367e94' },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${drawerWidth}px)` } }}
      >
        <Toolbar />
        {children}
        <Footer />
      </Box>
    </Box>
  );
}

// ResponsiveDrawer.propTypes = {
//   /**
//    * Injected by the documentation to work in an iframe.
//    * You won't need it on your project.
//    */
//   window: PropTypes.func,
// };

export default ResponsiveDrawer;