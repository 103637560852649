import React from 'react';

const Tokenomics = () => {
    return (
        <div className="grid h-screen place-items-center">
            <h1
                className="text-4xl font-bold text-white"
            >
                COMING SOON!
            </h1>
        </div>
    )
};

export default Tokenomics;