// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDCPmwog3qrxMkiL_9qZnK_viSZFp1hyPE",
  authDomain: "mericulum-backend.firebaseapp.com",
  projectId: "mericulum-backend",
  storageBucket: "mericulum-backend.appspot.com",
  messagingSenderId: "1073512506347",
  appId: "1:1073512506347:web:a833da1de02440dfb9afae"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// Initialize Firebase Authentication and get a reference to the service
export const auth = getAuth(app);
export const db = getFirestore(app);
export default app;