import React from 'react';
import roadmap from '../assets/images/roadmap.png';

const About = () => {
    return (
        <div className="grid h-screen place-items-center">
       <img className='' src={roadmap} alt='roadmap' />
        </div>
    )
};

export default About;