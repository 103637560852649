import React, { useEffect, useState } from 'react';
import { auth, db } from '../firebase';
import { Input, Button } from "@material-tailwind/react";
import { collection, query, where, doc, getDocs, updateDoc } from 'firebase/firestore';
import { DB_COLLECTIONS } from '../config';

const Profile = () => {
    const [name, setName] = useState('');
    const [taxId, setTaxId] = useState('');
    const [contactPerson, setContactPerson] = useState('');
    const [email, setEmail] = useState('');
    const [mobile, setMobile] = useState('');
    const [address, setAddress] = useState('');
    const [city, setCity] = useState('');
    const [country, setCountry] = useState('');
    const [zipCode, setZipCode] = useState('');

    useEffect(() => {
        let uid = auth?.currentUser?.uid;

        if (uid) {
            const companiesRef = collection(db, DB_COLLECTIONS.CUSTOMER);
            const q = query(companiesRef, where("AuthId", "==", uid));
            getDocs(q).then(result => {
                const customer = result.docs[0].data();
                setName(customer.name || '');
                setTaxId(customer.taxId || '');
                setContactPerson(customer.contactPerson || '');
                setEmail(customer.email || '');
                setMobile(customer.mobile || '');
                setAddress(customer.address || '');
                setCity(customer.city || '');
                setCountry(customer.country || '');
                setZipCode(customer.zipCode || '');
            });
        }
    }, [])

    const handleSubmit = async () => {
        let uid = auth.currentUser.uid;

        const companiesRef = collection(db, DB_COLLECTIONS.CUSTOMER);
        const q = query(companiesRef, where("AuthId", "==", uid));
        const querySnapshot = await getDocs(q);

        let customerDoc = querySnapshot.docs[0];
        await updateDoc(doc(db, DB_COLLECTIONS.CUSTOMER, customerDoc.id), {
            name: name,
            taxId: taxId,
            contactPerson: contactPerson,
            email: email,
            mobile: mobile,
            address: address,
            city: city,
            country: country,
            zipCode: zipCode
        });
    }

    return (
        <div className="grid place-items-left ml-10">
            <h1
                className="text-4xl font-bold text-white"
            >
                Profile
            </h1>
            <div className='mt-5 max-w-xl'>
                <form>
                    <div className="grid grid-col-5 gap-3 items-center">
                        <div className='col-start-1 col-span-1'>
                            <label className='text-white'>Email:</label>
                        </div>
                        <div className='col-start-2 col-span-4'>
                            <Input className='text-black' disabled label='Email' size='lg' value={email} onChange={event => setEmail(event.target.value)} />
                        </div>
                        <div className='col-start-1 col-span-1'>
                            <label className='text-white'>Company / Name:</label>
                        </div>
                        <div className='col-start-2 col-span-4'>
                            <Input className='text-white' label='Company / Name' size='lg' value={name} onChange={event => setName(event.target.value)} />
                        </div>
                        <div className='col-start-1 col-span-1'>
                            <label className='text-white'>Tax ID:</label>
                        </div>
                        <div className='col-start-2 col-span-4'>
                            <Input className='text-white' label='Tax ID' size='lg' value={taxId} onChange={event => setTaxId(event.target.value)} />
                        </div>
                        <div className='col-start-1 col-span-1'>
                            <label className='text-white'>Contact Person:</label>
                        </div>
                        <div className='col-start-2 col-span-4'>
                            <Input className='text-white' label='Contact Person' size='lg' value={contactPerson} onChange={event => setContactPerson(event.target.value)} />
                        </div>
                        <div className='col-start-1 col-span-1'>
                            <label className='text-white'>Mobile:</label>
                        </div>
                        <div className='col-start-2 col-span-4'>
                            <Input className='text-white' label="Mobile" size='lg' value={mobile} onChange={event => setMobile(event.target.value)} />
                        </div>
                        <div className='col-start-1 col-span-1'>
                            <label className='text-white'>Address:</label>
                        </div>
                        <div className='col-start-2 col-span-4'>
                            <Input className='text-white' label="Address" size='lg' value={address} onChange={event => setAddress(event.target.value)} />
                        </div>
                        <div className='col-start-1 col-span-1'>
                            <label className='text-white'>City:</label>
                        </div>
                        <div className='col-start-2 col-span-4'>
                            <Input className='text-white' label="City" size='lg' value={city} onChange={event => setCity(event.target.value)} />
                        </div>
                        <div className='col-start-1 col-span-1'>
                            <label className='text-white'>Country:</label>
                        </div>
                        <div className='col-start-2 col-span-4'>
                            <Input className='text-white' label="Country" size='lg' value={country} onChange={event => setCountry(event.target.value)} />
                        </div>
                        <div className='col-start-1 col-span-1'>
                            <label className='text-white'>Zip Code:</label>
                        </div>
                        <div className='col-start-2 col-span-4'>
                            <Input className='text-white' label="Zip Code" size='lg' value={zipCode} onChange={event => setZipCode(event.target.value)} />
                        </div>
                        <div className='col-start-2 col-span-4 mt-5'>
                            <Button type='button' onClick={handleSubmit} fullWidth>Save</Button>
                        </div>
                    </div>
                </form>
            </div>
        </div >
    )
};

export default Profile;