import React, { useState } from 'react';
import { db } from '../firebase';
import { collection, addDoc } from 'firebase/firestore';
import { DB_COLLECTIONS } from '../config';
import { Input, Button } from "@material-tailwind/react";
import { Snackbar, Alert } from '@mui/material';


const Contact = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [address, setAddress] = useState('');
    const [comment, setComment] = useState('');

    const [openSnack, setOpenSnack] = useState(false);
    const [severity, setSeverity] = useState('success');


    const handleSubmit = async (e) => {
        e.preventDefault()

        addDoc(collection(db, DB_COLLECTIONS.CONTACTS), {
            name: name,
            email: email,
            phone: phone,
            address: address,
            comment: comment
        })
            .then(_ => handleSubmitResponse('success'))
            .catch(_ => handleSubmitResponse('error'));
    }

    const handleClose = () => {
        setOpenSnack(false);
    }

    const handleSubmitResponse = (severity) => {
        setName('');
        setEmail('');
        setAddress('');
        setPhone('');
        setComment('');
        setSeverity(severity);
        setOpenSnack(true);
    }

    return (
        <div className='w-full mx-auto max-w-3xl shadow-xl bg-gray-900 p-8 text-center rounded-lg'>
            <h1 class="text-white w-full my-2 mb-10 text-3xl font-bold leading-tight">CONTACT</h1>
            <form id="contact-form" onSubmit={handleSubmit}>
                <div className='mb-5'>
                    <Input className='text-white' label='Name' size='lg' value={name} onChange={event => setName(event.target.value)} required />
                </div>
                <div className="mb-5">
                    <Input className='text-white mb-5' type='email' label='Email' size='lg' value={email} onChange={event => setEmail(event.target.value)} required />
                </div>
                <div className="mb-5">
                    <Input className='text-white mb-5' label='Phone' size='lg' value={phone} onChange={event => setPhone(event.target.value)} />
                </div>
                <div className="mb-5">
                    <Input className='text-white mb-5' label='Address' size='lg' value={address} onChange={event => setAddress(event.target.value)} />
                </div>
                <div className="mb-5">
                    <div class="relative w-full min-w-[200px]">
                        <textarea 
                            required
                            className="peer h-full min-h-[200px] w-full rounded-[7px] border border-blue-gray-200 border-t-transparent bg-transparent px-3 py-2.5 font-sans text-sm font-normal text-white outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-blue-500 focus:border-t-transparent focus:outline-0 disabled:resize-none disabled:border-0 disabled:bg-blue-gray-50"
                            placeholder=" "
                            value={comment}
                            onChange={event => setComment(event.target.value)}
                        ></textarea>
                        <label class="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-400 transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-blue-gray-200 before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-blue-gray-200 after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[3.75] peer-placeholder-shown:text-blue-gray-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-blue-500 peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:before:border-blue-500 peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-focus:after:border-blue-500 peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                            Message
                        </label>
                    </div>
                    {/* <Input className='text-white mb-5' label='Comments' size='lg' value={comment} onChange={event => setComment(event.target.value)} required /> */}
                </div>
                <div className='col-start-2 col-span-4 mt-5'>
                    <Button type='submit' fullWidth>Send</Button>
                </div>
            </form>
            <Snackbar
                autoHideDuration={3000}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                open={openSnack}
                onClose={handleClose}
            >
                <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
                    {severity === 'success' ? "Thanks, We will contact you as soon as possible" : "Something went wrong!"}
                </Alert>
            </Snackbar>
        </div>
    )

    // return (
    //     <div
    //         className="h-screen flex flex-col 
    //       items-center justify-center"
    //     >
    //         <div className="mb-3 pt-0">
    //             <h3 className="text-center text-gray-400 text-s">Contact Us</h3>
    //         </div>
    //         <form
    //             className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4"
    //             // action={FORM_ENDPOINT}
    //             onSubmit={handleSubmit}
    //             // method="POST"
    //             target="_blank"
    //         >
    //             <div className="mb-3 pt-0">
    //                 <input
    //                     type="text"
    //                     placeholder="Your name"
    //                     name="name"
    //                     className="px-3 py-3 placeholder-gray-400 text-gray-600 relative bg-white bg-white rounded text-sm border-0 shadow outline-none focus:outline-none focus:ring w-full"
    //                     required
    //                 />
    //             </div>
    //             <div className="mb-3 pt-0">
    //                 <input
    //                     type="email"
    //                     placeholder="Email"
    //                     name="email"
    //                     className="px-3 py-3 placeholder-gray-400 text-gray-600 relative bg-white bg-white rounded text-sm border-0 shadow outline-none focus:outline-none focus:ring w-full"
    //                     required
    //                 />
    //             </div>
    //             <div className="mb-3 pt-0">
    //                 <textarea
    //                     placeholder="Your message"
    //                     name="message"
    //                     className="px-3 py-3 placeholder-gray-400 text-gray-600 relative bg-white bg-white rounded text-sm border-0 shadow outline-none focus:outline-none focus:ring w-full"
    //                     required
    //                 />
    //             </div>
    //             <div className="mb-3 pt-0">
    //                 <button
    //                     className="bg-blue-500 text-white active:bg-blue-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
    //                     type="submit"
    //                 >
    //                     Send a message
    //                 </button>
    //             </div>
    //         </form>
    //     </div>
    // );
};


export default Contact;