import React from 'react';
import video from '../assets/videos/news.mp4';

const News = () => {
    return (

       <div>
        <video src={video} autoPlay={true}/>
      </div>
    )
};

export default News;