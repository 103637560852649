const TOKEN_NAME = 'mericulumToken';
const DB_COLLECTIONS = {
    CUSTOMER: 'customer',
    CONTACTS: 'contacts'
}
const ROUTES = {
    LOGIN: '/login',
    SIGNUP: '/signup',
    HOME: '/home',
    ABOUT: '/about',
    CONTACT: '/contact',
    PROFILE: '/profile',
    TOKENOMICS: '/tokenomics',
    MARKETPLACE: '/marketplace',
    TESTIMONIAL: '/testimonial',
    NEWS: '/news'
};

export { TOKEN_NAME, DB_COLLECTIONS, ROUTES };