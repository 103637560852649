import React, { useState } from 'react';
import Text from '../components/elements/Text';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { auth } from '../firebase';
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import { NavLink, useNavigate } from 'react-router-dom'
import { ROUTES, TOKEN_NAME } from '../config';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { Button } from '@mui/material';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Login = () => {
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false)

    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState("");
    const [severity, setSeverity] = useState("error")

    const showMessage = (message, severity) => {
        setMessage(message);
        setSeverity(severity);
        setOpen(true);
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    const handlePasswordType = () => {
        setShowPassword(!showPassword)
    }

    const onLogin = (e) => {
        e.preventDefault();
        if (!!email && !!password) {
            signInWithEmailAndPassword(auth, email, password)
                .then((userCredential) => {
                    // Signed in 
                    sessionStorage.setItem(TOKEN_NAME, userCredential._tokenResponse.refreshToken)
                    navigate("/home")
                })
                .catch((error) => {
                    const errorCode = error.code;
                    console.log(errorCode)
                    // console.log("Test")
                    if (error.code === "auth/user-not-found")
                        showMessage("Account not found! Please sign up.", "error")
                    else if (error.code === "auth/wrong-password")
                        showMessage("Email or password wrong!", "error")
                    else
                        showMessage("Login failed!", "error")
                });
        }
    }

    const resetPassword = () => {
        const auth = getAuth();
        sendPasswordResetEmail(auth, email)
            .then(() => {
                showMessage("Thank you! We've sent a password reset link. Please check your email inbox for further instructions", "info")
            })
            .catch((error) => {
                const errorCode = error.code;
                // const errorMessage = error.message;
                // console.log(errorCode)

                if (errorCode === "auth/missing-email")
                    showMessage("Please enter your email first!", "warning")
                else if (errorCode === "auth/user-not-found")
                    showMessage("Unknown Email! Please sign up first.", "warning")
                else
                    showMessage("Resetting passowrd failed!", "error")
            });
    }

    return (
        <div className="App default-bg-color">
            <main >
                <section>
                    <div className="flex h-screen items-center justify-center px-4 sm:px-6 lg:px-8">
                        <div className="w-full max-w-md space-y-8">
                            <div>
                                <NavLink to={ROUTES.HOME}>
                                    <Text className="text-4xl text-white text-center font-bold mb-2">
                                        MERICULUM
                                    </Text>
                                </NavLink>

                                <h2 className="text-white text-center text-base  tracking-tight">
                                    Welcome
                                </h2>
                            </div>

                            <form className="mt-8 space-y-6" >
                                <div className=" space-y-6 rounded-md shadow-sm">


                                    <div>
                                        <label htmlFor="email-address" className="sr-only">
                                            Email address
                                        </label>
                                        <input
                                            id="email-address"
                                            name="email"
                                            type="email"
                                            required
                                            className="relative block w-full appearance-none rounded-none rounded-t-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                            placeholder="Email address"
                                            onChange={(e) => setEmail(e.target.value)}
                                        />
                                    </div>

                                    <div>
                                        <label htmlFor="password" className="sr-only">
                                            Password
                                        </label>
                                        <input
                                            id="password"
                                            name="password"
                                            type={showPassword ? "text" : "password"}
                                            required
                                            className="relative block w-full appearance-none rounded-none rounded-b-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                            placeholder="Password"
                                            onChange={(e) => setPassword(e.target.value)}
                                        />
                                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <div>
                                                <input className='mt-3' type="checkbox" onClick={handlePasswordType} />
                                                <span style={{ color: 'white' }}> Show password</span>
                                            </div>
                                            <div>
                                                <Button color='warning' onClick={resetPassword}>forgot password?</Button>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                                <div>
                                    <button
                                        onClick={onLogin}
                                        className="group relative flex w-full justify-center rounded-md border border-transparent bg-black py-2 px-4 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                    >
                                        Login
                                    </button>
                                </div>

                            </form>

                            <p className="text-sm text-white text-center">
                                No account yet?{' '}
                                <NavLink to="/signup" className="underline text-tertiary">
                                    Sign up
                                </NavLink>
                            </p>

                        </div>
                    </div>
                </section>
                <Snackbar open={open}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                    autoHideDuration={6000}
                    onClose={handleClose}>
                    <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
                        {message}
                    </Alert>
                </Snackbar>
            </main>
        </div>
    )
}

export default Login