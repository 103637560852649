import React from 'react'
import copyright from '../assets/images/copyright.png';
import { Divider } from '@mui/material';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import EmailIcon from '@mui/icons-material/Email';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import YouTubeIcon from '@mui/icons-material/YouTube';
import { ROUTES } from '../config';

export default function Footer() {
    return (
        <>
            <div className='home md:mt-10 mx-auto default-bg-color'>
                <Divider />
                <div className='text-white pt-10 flex flex-row mx-auto md:px-10 tracking-wide'>
                    <div className=' w-1/3 text-center'>
                        <h1 className='text-sm md:text-xl font-black'>
                            MERICULUM
                        </h1>
                        <div className='text-xs md:text-base flex flex-col md:mt-6 space-y-2 md:space-y-3'>
                            <a className='' href={ROUTES.ABOUT}>Roadmap</a>
                            <a className='' href={ROUTES.NEWS}>News</a>
                            {/* <a className='' href="/">Careers</a> */}
                            {/* <a className='' href="/" >Partnership Program</a> */}

                        </div>

                    </div>
                    <div className='w-1/3 text-center'>
                        <h1 className='text-sm font-black md:text-xl '>
                            Solutions
                        </h1>
                        <div className='text-xs md:text-base flex flex-col md:mt-6 space-y-2 md:space-y-3'>
                            <a className='' href={ROUTES.TOKENOMICS}>Data Platform</a>
                            <a className='' href={ROUTES.MARKETPLACE}>Marketplace</a>
                            {/* <a className='' href="/" >Mobile App</a> */}
                        </div>

                    </div>
                    <div className='w-1/3 text-center'>
                        <h1 className='text-sm font-black md:text-xl'>
                            Knowledge hub
                        </h1>
                        <div className='text-xs md:text-base flex flex-col md:mt-6 space-y-2 md:space-y-3'>
                            <a className='' href="https://t.me/mericulum">Telegram Community</a>
                            <a className='' href="https://medium.com/@mericulum" target="_blank" rel="noreferrer">Blog</a>
                            {/* <a className='' href="/" >FAQ</a> */}
                            <a className='' href="/" >Privacy Policies</a>
                            <a className='' href="/" >Terms & Conditions</a>
                        </div>
                    </div>
                </div>

                <div className=' px-8 mt-10 flex flex-col mx-auto space-y-4'>
                    <div className='flex flex-row space-x-4'>
                        <a href='https://www.linkedin.com/company/mericulum/' target="_blank" rel="noreferrer">
                            <LinkedInIcon fontSize='large' />
                        </a>
                        <a href='https://twitter.com/MERICULUM' target="_blank" rel='noreferrer'>
                            <TwitterIcon fontSize='large' />
                        </a>
                        <a href='https://docs.google.com/forms/d/e/1FAIpQLSfwo9zFc2bmGLXtPH3n2iXB1HEJ3sNrXgICBbpprdWAlnFsiA/viewform?embedded=true' target="_blank" rel='noreferrer'>
                            <EmailIcon fontSize='large' />
                        </a>
                        <a href='https://www.instagram.com/mericulum_plastic/' target="_blank" rel="noreferrer">
                            <InstagramIcon fontSize='large' />
                        </a>
                        <a href='https://www.youtube.com/@mericulum' target="_blank" rel='noreferrer'>
                            <YouTubeIcon fontSize='large' />
                        </a>
                    </div>
                    <div className='text-slate-800 pb-10 flex flex-row space-x-1 tracking-wide'>
                        <img className='h-4 w-4' alt='copyright logo' src={copyright} ></img>
                        <h1 className='text-xs text-white'>
                            Copyright 2023 Mericulum. All rights reserved.
                        </h1>
                    </div>

                </div>
            </div>
        </>
    )
}
